.pattern-filter {
    position: fixed;
    top: 0;
    left: 360px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;
    background: #adb5bd;
    width: 100px;
    height: 100%;
    overflow-y: scroll;
    color: #495057;
    box-shadow: inset 2px 0 5px rgba(52, 58, 64, 0.5);

    &::-webkit-scrollbar {
        display: none;
    }
}
