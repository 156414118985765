.patterns-not-found {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 300px;


  .ant-typography {
    color: #6c757d;
    font-size: 30px;
    font-weight: 500;
  }
}