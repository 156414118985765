@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap');

@font-face {
    font-family: "Glyphs";
    src: url("../asset/fonts/glyphs.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}

body {
    font-family: Roboto;
    min-height: 100vh;
    background: #f8f9fa;
}