.instrumental-panel {
    position: fixed;
    top: 0;
    left: 260px;
    display: flex;
    flex-direction: column;
    padding: 20px;
    justify-content: center;
    align-items: center;
    background: #6c757d;
    width: 100px;
    height: 100%;
    overflow-y: scroll;
    box-shadow: inset 2px 0 5px rgba(52, 58, 64, 0.5);

    &::-webkit-scrollbar {
        display: none;
    }

    &--item {
        font-size: 40px;
        color: rgba(255, 255, 255, 0.85);
        cursor: pointer;
        transition: all 0.1s;

        &:hover {
            color: rgba(255, 255, 255, 1);
            transform: scale(1.05);
        }
    }
}
