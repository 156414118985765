.pattern {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-left: 100px;

  &--canvas-container {
    display: flex;
    width: 1300px;
    padding: 50px;
    margin: 0 auto;
  }
}
