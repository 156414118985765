.filter {
    margin: 0 10px;
    cursor: pointer;
}

.filter-color {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    outline: 3px solid transparent;
    color: black;
}

.filter-counter {
    padding: 5px 0;
    font-size: 14px;
    color: white;
    text-align: center;
}