.layout {
  display: flex;

  &--main-container {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    flex: 1;
    max-width: calc(100vw - 260px);
  }
}



