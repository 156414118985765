.scheme-preview {
  padding: 10px;
  background: #ffffff;
  border-radius: 10px;
  color: #282c34;
  height: 100px;
  cursor: pointer;
  box-shadow: 0 1px 0 0 #dce1e6, 0 0 0 1px #e7e8ec;

  &:hover {
    box-shadow: 0 0 15px #6ea8fe;
  }

  .content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .wrapper {
    display: flex;
    flex-direction: row;
  }

  .image {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
    background: #cfe2ff;
    border-radius: 10px;
    overflow: hidden;

    img {
      width: 100%;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    overflow: hidden;
    justify-content: space-between;
  }

  .title {
    font-size: 18px;
    font-weight: 500;
    line-height: 1.2;
    color: #282c34;

    .ant-typography {
      margin-bottom:0;
    }
  }

  .meta-data {
    display: flex;
    flex-direction: row;
  }

  .meta-data-item {
    margin-right: 15px;
    font-size: 12px;
    color: #6c757d;

    &:last-child {
      margin-right: 0;
    }

    .anticon {
      margin-right: 3px;
    }
  }

  .progress {
    flex-shrink: 0;
    width: 80px;
    height: 80px;
  }
}
