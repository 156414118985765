.menu {
  position: sticky;
  top: 0;
  display: flex;
  padding: 24px;
  width: 260px;
  height: 100vh;
  overflow-y: scroll;
  flex-direction: column;
  align-items: flex-start;
  background-color: #343a40;
  color: #6c757d;

  &--list {
    list-style: none;
    padding: 0;
    margin: 0;
  }

  &--logo {
    width: 50px;
    height: 50px;
    margin-bottom: 100px;
  }

  &--socials-link.ant-typography {
    display: inline-block;
    font-size: 30px;
    color: rgba(255, 255, 255, 0.7);

    &:hover {
      color: rgba(255, 255, 255, 1);
    }
  }
}

.menu::-webkit-scrollbar {
  display: none;
}

.nav-link {
  display: inline-block;
  font-size: 18px;
  padding: 8px 8px 8px 0;
  color: rgba(255, 255, 255, 0.9);
  font-weight: 500;
  transition: color 0.1s;

  &:hover {
    color: rgba(255, 255, 255, 1);
  }

  &_small {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.65);

    &:hover {
      color: rgba(255, 255, 255, 0.9);
    }
  }

  .anticon {
    margin-right: 5px;
  }
}